import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { default as pathMap } from '~/shared/pathMap';
import { useCreateCalendlyLink } from '~/shared/calendly';
import type { HeaderProducts, TranslationPrice } from '~/types';
import { Price, PriceToText } from '../price';
import Link from 'next/link';
import { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { TranslationPricesContext } from '~/pages';
import { useCountryCode } from '~/lib/hooks/useCountryCode';

type Props = {
  className?: string;
  headerProducts: HeaderProducts;
};

const MarkTrans = ({
  i18nKey,
  className,
  translationPrice
}: {
  i18nKey: string;
  className?: string;
  translationPrice?: TranslationPrice;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale } = router;
  const price = PriceToText({
    locale: locale || 'en-GB',
    countryCode: translationPrice?.countryCode || 'GB',
    price: translationPrice?.price,
    t
  });

  return (
    <Trans
      className={className}
      i18nKey={i18nKey}
      components={{
        strong: <span className="text-[#FF6D00]" />
      }}
      values={{
        price
      }}
    />
  );
};

const HeaderSlider = ({ className, headerProducts }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const countryCode = useCountryCode();

  let totalPrice = -1;

  const translationPlacerholderPrices = useContext(TranslationPricesContext);

  if (headerProducts.robot != -1 && headerProducts.sensor != -1 && headerProducts.endEffector != -1) {
    totalPrice = headerProducts.robot + headerProducts.sensor + headerProducts.endEffector;
  }

  const totalPriceRef = useRef<HTMLDivElement>(null);
  const totalPriceContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustTotalPriceSize = () => {
      if (totalPriceRef.current && totalPriceContainerRef.current) {
        const containerWidth = totalPriceContainerRef.current.clientWidth;
        const text = totalPriceRef.current.textContent;
        if (text) {
          const fontSize = (containerWidth / text.length) * 1.9;
          totalPriceRef.current.style.fontSize = `${fontSize}px`;
        }
      }
    };

    // Initial adjustment
    adjustTotalPriceSize();

    // Adjust text size on window resize
    window.addEventListener('resize', adjustTotalPriceSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustTotalPriceSize);
    };
  }, [totalPriceRef?.current?.textContent]);

  // essentially the same logic as the Price component

  SwiperCore.use([Autoplay, Pagination, Navigation]);
  return (
    <div id="header-slider" className={classNames('rbtx-header-slider', className)}>
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay, Navigation]}
        navigation={true}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: true, pauseOnMouseEnter: true }}
      >
        <SwiperSlide>
          <div className="absolute top-[15%] text-center w-full lg:top-[30%] lg:left-[3vw] lg:w-auto lg:text-left">
            <div className="text-[9vw] text-white font-semibold lg:text-[4vw]">
              <MarkTrans
                i18nKey={t('landing:slider.slider-build.title.0', {
                  defaultValue: ''
                })}
              />
            </div>
            <div className="text-[9vw] text-white font-semibold mt-[-4vw] lg:-mt-[1vw] lg:text-[4vw]">
              <MarkTrans
                translationPrice={translationPlacerholderPrices['landing-automate-factory-minimum']}
                i18nKey={t('landing:slider.slider-build.title.1', {
                  defaultValue: ''
                })}
              />
            </div>
            <div className="hidden lg:block text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white mt-3 lg:mt-0">
              <MarkTrans i18nKey={t('landing:slider.slider-build.subtitle.0', { defaultValue: '' })} />
            </div>
            <div className="hidden lg:block text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white lg:mt-0">
              <MarkTrans
                i18nKey={t('landing:slider.slider-build.subtitle.1', { defaultValue: '' })}
                translationPrice={translationPlacerholderPrices['landing-automate-factory-95p-under']}
              />
            </div>
            <div className="hidden lg:block text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white mb-3 lg:mt-0">
              <MarkTrans i18nKey={t('landing:slider.slider-build.subtitle.2', { defaultValue: '' })} />
            </div>
            <div>
              <button
                onClick={() => router.push(pathMap.components.pathname)}
                className="bg-brand-orange hover:bg-orange-600 mt-12 lg:mt-0 text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white px-[8vw] py-[2vw] lg:px-[2vw] lg:py-[1vw] rounded-full "
              >
                {t('landing:slider.slider-build.buildNow')}
              </button>
            </div>
          </div>
          <div className="absolute top-[74.8%] left-[17.3%] lg:top-[43%] lg:left-[51.5%]">
            <div className="text-[2vw] lg:text-[1.2vw] text-white">
              {t('landing:slider.slider-build.gripper')}{' '}
            </div>
            <div className="text-[3vw] lg:text-[1.5vw] text-white font-strong">
              <Price price={headerProducts.endEffector} />
            </div>
          </div>
          <div className="absolute top-[68.3%] right-[10%] lg:top-[28.4%] lg:right-[4.8%] text-right">
            <div className="text-[2vw] lg:text-[1.2vw] text-white">
              {t('landing:slider.slider-build.rebelCobot')}{' '}
            </div>
            <div className="text-[3vw] lg:text-[1.5vw] text-white font-strong">
              <Price price={headerProducts.robot} />
            </div>
          </div>
          <div className="absolute top-[89.2%] left-[7.1%] lg:top-[75.6%] lg:left-[45.5%]">
            <div className="text-[2vw] lg:text-[1.2vw] text-white">
              {t('landing:slider.slider-build.sensor')}{' '}
            </div>
            <div className="text-[3vw] lg:text-[1.5vw] text-white font-strong">
              <Price price={headerProducts.sensor} />
            </div>
          </div>
          <div className="absolute lg:top-[80.5%] lg:left-[86.3%] top-[91.2%] left-[74.6%] -rotate-[10.5deg]">
            <div
              ref={totalPriceContainerRef}
              className="flex justify-center text-white font-strong w-[15.6vw] h-[7.9vw] max-w-[15.6vw] max-h-[7.9vw] lg:w-[8.76vw] lg:h-[4.3vw] lg:max-w-[8.76vw] lg:max-h-[4.3vw]"
            >
              <div ref={totalPriceRef} className="flex text-center justify-center items-center">
                <Price price={totalPrice} />
              </div>
            </div>
          </div>
          <Image
            className="lg:hidden block"
            src="/images/header/bg-slide-md-1.png"
            width={1000}
            height={1692}
            alt="header"
          />
          <Image
            className="lg:block hidden"
            src="/images/header/bg-slide-1.png"
            width={5000}
            height={2248}
            alt="header"
          />
        </SwiperSlide>
        <SwiperSlide>
          <div className="absolute top-[10%] text-center w-full lg:top-[30%] lg:left-[3vw] lg:text-left">
            <div className="text-[9vw] lg:text-[4vw] text-white font-semibold">
              <MarkTrans i18nKey={t('landing:slider.slider-book.title.0', { defaultValue: '' })} />
            </div>
            <div className="text-[9vw] lg:text-[4vw] text-white font-semibold mt-[-4vw] lg:-mt-[1vw]">
              <MarkTrans i18nKey={t('landing:slider.slider-book.title.1', { defaultValue: '' })} />
            </div>
            <div className="lg:block hidden text-[9vw] lg:text-[4vw] text-white font-semibold mt-[-4vw] lg:-mt-[1vw]">
              <MarkTrans i18nKey={t('landing:slider.slider-book.title.2', { defaultValue: '' })} />
            </div>
            <div className="lg:hidden block text-[9vw] lg:text-[4vw] text-white font-semibold mt-[-4vw] lg:-mt-[1vw]">
              <MarkTrans i18nKey={t('landing:slider.slider-book.mobileTitle.2', { defaultValue: '' })} />
            </div>
            <div className="text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white mt-7 lg:mt-0">
              <MarkTrans i18nKey={t('landing:slider.slider-book.subtitle.0', { defaultValue: '' })} />
            </div>
            <div className="text-[5vw] px-[2vw] lg:px-0 lg:text-[1.4vw] 2xl:text-[1.2vw] text-white  mb-[5vw] lg:my-0 lg:pb-[1.5vw]">
              <MarkTrans i18nKey={t('landing:slider.slider-book.subtitle.1', { defaultValue: '' })} />
            </div>
            <div className="flex flex-col lg:block space-y-[3vw] lg:space-y-0 mx-[28%] lg:mx-0 lg:mt-[1vw]">
              <Link
                href={useCreateCalendlyLink(router.locale)}
                className="bg-brand-orange border-brand-orange border-2 hover:border-orange-600 hover:bg-orange-600 text-[5vw] text-white lg:text-[1.4vw] 2xl:text-[1.2vw] py-[2vw] lg:px-[2vw] lg:py-[1vw] rounded-full"
              >
                {t('landing:slider.slider-book.bookNow')}
              </Link>
              <Link
                href={pathMap.rbtxpert.as()}
                className="border-brand-orange border-2 text-[5vw] text-white lg:ml-4 lg:text-[1.4vw] 2xl:text-[1.2vw] py-[2vw] lg:px-[2vw] lg:py-[1vw] rounded-full "
              >
                {t('calendlyModal.learnMore')}
              </Link>
            </div>
          </div>

          {countryCode && (
            <>
              <Image
                className="lg:hidden block"
                src={
                  countryCode == 'CN'
                    ? '/images/header/bg-slide-md-2-cn.png'
                    : '/images/header/bg-slide-md-2.png'
                }
                width={1000}
                height={1692}
                alt="header"
              />
              <Image
                className="lg:block hidden"
                src={
                  countryCode == 'CN' ? '/images/header/bg-slide-2-cn.png' : '/images/header/bg-slide-2.png'
                }
                width={5000}
                height={2248}
                alt="header"
              />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide>
          <div className="absolute top-[15%] text-center w-full lg:text-right lg:top-[35%] lg:right-[3vw]">
            <div className="text-[10vw] lg:text-[5vw] text-white font-semibold">
              <MarkTrans i18nKey={t('landing:slider.slider-test.title')} />
            </div>
            <div className="lg:hidden block">
              <div className="text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white mt-[10vw] lg:mt-0">
                <MarkTrans i18nKey={t('landing:slider.slider-test.subtitle.0')} /> <br />
              </div>
              <div className="text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white mb-[7vw] lg:mb-0">
                <MarkTrans i18nKey={t('landing:slider.slider-test.subtitle.1')} />
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="lg:text-[1.4vw] 2xl:text-[1.2vw] text-white">
                <MarkTrans i18nKey={t('landing:slider.slider-test.subtitle.0')} />
              </div>
              <div className="lg:text-[1.4vw] 2xl:text-[1.2vw] text-white pb-[1.5vw]">
                <MarkTrans i18nKey={t('landing:slider.slider-test.subtitle.1')} />
              </div>
            </div>
            <div className="lg:mt-[1vw]">
              <button
                onClick={() => router.push(pathMap.customerTestingArea.pathname)}
                className="bg-brand-orange hover:bg-orange-600 text-[5vw] lg:text-[1.4vw] 2xl:text-[1.2vw] text-white px-[8vw] py-[2vw] lg:px-[2vw] lg:py-[1vw] rounded-full"
              >
                {t('landing:slider.slider-test.testNow')}
              </button>
            </div>
          </div>
          {countryCode && (
            <>
              <Image
                className="lg:hidden block"
                src={
                  countryCode == 'CN'
                    ? '/images/header/bg-slide-md-3-cn.png'
                    : '/images/header/bg-slide-md-3.png'
                }
                width={1000}
                height={1692}
                alt="header"
              />
              <Image
                className="lg:block hidden"
                src={
                  countryCode == 'CN' ? '/images/header/bg-slide-3-cn.png' : '/images/header/bg-slide-3.png'
                }
                width={5000}
                height={2248}
                alt="header"
              />
            </>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HeaderSlider;
