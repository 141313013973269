import classNames from 'classnames';
import { useEffect, useState } from 'react';
import type { IconProps } from 'react-feather';
import { twMerge } from 'tailwind-merge';
import Header from '~/components/header';
import HeaderSlider from '~/components/header-slider';
import RobotBundle from '~/components/robot-bundle';
import { useBundle } from '~/lib/hooks/useBundle';
import type { HeaderProducts } from '~/types';

// helper types so we can have a tuple of a fixed length
type Tuple<T, N extends number> = N extends N ? (number extends N ? T[] : _TupleOf<T, N, []>) : never;
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>;

type CTAItem = {
  id: string;
  title: string;
  subTitle: string;
  description: string;
  ctaText: string;
  onClick: () => void;
  icon: React.FC<IconProps>;
};

export type CtaItems = Tuple<CTAItem, 3>;

type Props = {
  children: React.ReactNode;
  ctaItems: CtaItems;
  className?: string;
  headerProducts: HeaderProducts;
  disablePadding?: boolean;
};
const PADDING_CLASS = 'md:px-6 lg:px-20';

export const HERO_BACKGROUND_GRADIENT = 'bg-gradient-to-b from-brand-darkPurple to-brand-darkPurple/80';

export function CTALayout({ children, className, disablePadding = false, headerProducts }: Props) {
  const {
    bundleLoading,
    cartCount,
    handleRobotBundleTabClick,
    handleBundleClose,
    handleBundleLoad,
    handleBundleUpdate,
    showBundle
  } = useBundle();

  const [localeBannerExists, setLocaleBannerExists] = useState(false);

  // NOTE: Adding [] as dependency seems to break it for some reason
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = document.getElementById('locale-banner');
    setLocaleBannerExists(!!el);
  });

  return (
    <>
      <main
        role="main"
        className={classNames(
          'flex flex-col min-h-screen h-full relative w-screen',
          localeBannerExists && 'mt-[80px]'
        )}
      >
        <Header
          onBundleTabClick={handleRobotBundleTabClick}
          showBundleSpinner={bundleLoading}
          cartProductCount={cartCount}
          isTransparent
          transparencyToggleTargetId="header-slider"
          isFixed={true}
        />
        <section className={classNames(twMerge(HERO_BACKGROUND_GRADIENT, className))}>
          <RobotBundle
            showBundle={showBundle}
            onBundleLoaded={handleBundleLoad}
            onBundleClosed={handleBundleClose}
            onBundleUpdate={handleBundleUpdate}
          />
          <HeaderSlider headerProducts={headerProducts} className="relative z-0" />
        </section>
        <div className="block lg:hidden flex-1 w-full">{children}</div>
        <div className="hidden lg:block z-0">
          <div className={classNames('w-full', PADDING_CLASS)}>
            <div
              className={classNames('bg-white md:-mx-6 lg:-mx-20', {
                [PADDING_CLASS]: !disablePadding
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
