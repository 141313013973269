import type { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { CallIcon, CheckIcon, ToolIcon } from '~/components/community/icons';
import Footer from '~/components/footer';
import LeadMagnet from '~/components/lead-magnet';
import PartnerList from '~/components/partner-list';
import { ProductsSection } from '~/components/products-section';
import SeoAlternateLinks from '~/components/seo-alternate-links';
import { UsecaseSection } from '~/components/usecase-section';
import { CTALayout, type CtaItems } from '~/layouts/CTALayout';
import { AxiosRequestConfigWithCancel, RequestApi } from '~/lib/api-client';
import { env } from '~/lib/env';
import { useBundle } from '~/lib/hooks/useBundle';
import { useCalendlyModal } from '~/lib/hooks/useCalendlyModal';
import { getRevalidationTime } from '~/lib/nextHelpers';
import { default as PathMap, default as pathMap } from '~/shared/pathMap';
import type {
  Category,
  HeaderProducts,
  Partner,
  Product,
  TranslationPrice,
  Usecase,
  UsecaseCategory
} from '~/types';
import OrganizationJsonLd from '~/components/json-ld/organization';
import ServicesSection from '~/components/services-section/free-services';
import AwardsSection from '~/components/awards-section';
import { TestimonailsSection } from '~/components/testimonials-section';
import { ContentfulService } from '~/server/contentful';
import { createContext } from 'react';

const RobotBundle = dynamic(import('~/components/robot-bundle'), {
  ssr: false
});

export const TranslationPricesContext = createContext<{
  [key: string]: TranslationPrice;
}>({});

interface Props {
  categories: Array<Category>;
  partners: Array<Partner>;
  stage: any;
  robots: {
    products: Array<Product>;
  };
  prefabs: Array<Product>;
  headerProducts: HeaderProducts;
  translationPrices: {
    [key: string]: {
      price: number;
      countryCode: string;
    };
  };
  usecaseCategories: Array<UsecaseCategory>;
  initialCategoryUsecases: Array<Usecase>;
  testimonials: Array<any>;
}

function IndexPage({
  partners,
  robots,
  categories,
  headerProducts,
  translationPrices,
  usecaseCategories,
  initialCategoryUsecases,
  testimonials
}: Props) {
  const showCalendlyModal = useCalendlyModal((state) => state.setIsOpen);
  const router = useRouter();
  const { t } = useTranslation();
  const { handleBundleClose, handleBundleLoad, handleBundleUpdate, showBundle } = useBundle();

  const CTA_ITEMS: CtaItems = [
    {
      id: 'cta-rbtxpert',
      title: t('landing:consultationCTA.title'),
      subTitle: t('landing:consultationCTA.subtitle'),
      description: t('landing:consultationCTA.description'),
      ctaText: t('landing:consultationCTA.ctaText'),
      icon: CallIcon,
      onClick: () => {
        showCalendlyModal(true);
      }
    },
    {
      id: 'cta-customer-testing-area',
      title: t('landing:customerTestingAreaCTA.title'),
      subTitle: t('landing:customerTestingAreaCTA.subtitle'),
      description: t('landing:customerTestingAreaCTA.description'),
      ctaText: t('landing:customerTestingAreaCTA.ctaText'),
      icon: CheckIcon,
      onClick: () => {
        router.push(pathMap.customerTestingArea.pathname);
      }
    },
    {
      id: 'cta-components',
      title: t('landing:customBuildCTA.title'),
      subTitle: t('landing:customBuildCTA.subtitle'),
      description: t('landing:customBuildCTA.description'),
      ctaText: t('landing:customBuildCTA.ctaText'),
      icon: ToolIcon,
      onClick: () => {
        router.push(pathMap.components.pathname);
      }
    }
  ];

  return (
    <>
      <Head>
        <title>{t('home:pageTitle')}</title>
        <meta name="description" content={t('home:description')} />
        <meta property="og:title" content={t('common:defaultMetaTitle')} />
        <meta property="og:description" content={t('common:defaultMetaDescription')} />
        <meta name="twitter:title" content={t('common:defaultMetaTitle')} />
        <meta name="twitter:image" content="/images/meta/default.png" />
      </Head>
      <SeoAlternateLinks defaultPath={PathMap.index.as()} canonicalPath={PathMap.index.as()} />
      <OrganizationJsonLd />
      <TranslationPricesContext.Provider value={translationPrices}>
        <CTALayout ctaItems={CTA_ITEMS} headerProducts={headerProducts} disablePadding>
          <PartnerList partners={partners} className="bg-brand-lightGray" />
          <ProductsSection categories={categories ?? []} products={robots.products ?? []} />
          <ServicesSection className="bg-brand-lightGray" />
          <UsecaseSection
            categories={usecaseCategories}
            initialUsecases={initialCategoryUsecases}
            limit={8}
          />
          <AwardsSection />
          <TestimonailsSection testimonials={testimonials} className="bg-brand-lightGray" />
          <LeadMagnet theme="purple" name="bottom" />
          <RobotBundle
            showBundle={showBundle}
            onBundleUpdate={handleBundleUpdate}
            onBundleLoaded={handleBundleLoad}
            onBundleClosed={handleBundleClose}
          />
          <Footer className="mt-0" />
        </CTALayout>
      </TranslationPricesContext.Provider>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const categoriesRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    url: `/categories`,
    cancelOnRouteChange: true
  };

  const usecaseListRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    url: `/usecasesByCategoryId/${env.CONTENTFUL_INITIAL_USECASE_CATEGORY_ID}?limit=8`,
    cancelOnRouteChange: true
  };

  const usecaseApplicationsListRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    url: `/usecases-applications`,
    cancelOnRouteChange: true
  };

  const usecaseIndustriesListRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    url: `/usecases-industries`,
    cancelOnRouteChange: true
  };

  const partnerListRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    url: `/partners`,
    cancelOnRouteChange: true
  };

  const sensorProductPriceRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    params: {
      categorySlug: 'vision-sensors',
      productSlug: 'ifm-o2d220',
      priceOnly: true
    },
    url: '/products',
    cancelOnRouteChange: true
  };

  const rebelProductPriceRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    params: {
      categorySlug: 'robots',
      productSlug: 'rebel-cobot-6-degrees-of-freedom-reach-664-mm',
      priceOnly: true
    },
    url: '/products',
    cancelOnRouteChange: true
  };

  const grabberProductPriceRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    params: {
      categorySlug: 'end-effectors',
      productSlug: 'schunk-egp-40-electric-gripper',
      priceOnly: true
    },
    url: '/products',
    cancelOnRouteChange: true
  };

  const translationPricesRequest: AxiosRequestConfigWithCancel = {
    method: 'get',
    params: {
      productKey: [
        'landing-automate-factory-minimum',
        'landing-automate-factory-95p-under',
        'landing-solutions-subtitle-95p-under'
      ]
    },
    url: '/translationPlaceholderPrice',
    cancelOnRouteChange: true
  };

  const data = {
    intent: ''
  };

  const [
    categoryResponse,
    usecases,
    usecaseApplications,
    usecaseIndustries,
    partnerListResponse,
    sensorProductPriceResponse,
    rebelProductPriceResponse,
    grabberProductPriceResponse,
    translationPricesResponse,
    robots
  ] = await Promise.all([
    RequestApi(categoriesRequest, ctx),
    RequestApi<{ usecases: Array<Usecase> }>(usecaseListRequest, ctx),
    RequestApi<{ usecaseApplications: Array<UsecaseCategory> }>(usecaseApplicationsListRequest, ctx),
    RequestApi<{ usecaseIndustries: Array<UsecaseCategory> }>(usecaseIndustriesListRequest, ctx),
    RequestApi(partnerListRequest, ctx),
    RequestApi(sensorProductPriceRequest, ctx).catch(() => {
      // TODO catch error
    }),
    RequestApi(rebelProductPriceRequest, ctx).catch(() => {
      // TODO catch error
    }),
    RequestApi(grabberProductPriceRequest, ctx).catch(() => {
      // TODO catch error
    }),
    RequestApi(translationPricesRequest, ctx).catch(() => {
      // TODO catch error
    }),
    RequestApi(
      {
        method: 'GET',
        url: `/catalog/robots/components`
      },
      ctx
    ).catch(() => {
      // dev only issue. Some categories don't exist for the french locale
      // just catch them inline and filter them out below
    })
  ]);

  const usecaseCategories: Array<UsecaseCategory> = [
    ...usecaseApplications.usecaseApplications,
    ...usecaseIndustries.usecaseIndustries
  ].map((cat) => {
    return {
      id: cat.id,
      slug: cat.slug,
      title: cat.title,
      icon: cat.icon ?? null
    };
  });

  const testimonials = await ContentfulService.getTestimonials({ ctx });

  ctx.res.setHeader('Cache-Control', `s-maxage=${getRevalidationTime()}`);

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale!)),
      ...data,
      ...categoryResponse,
      ...partnerListResponse,
      headerProducts: {
        sensor: sensorProductPriceResponse ? sensorProductPriceResponse.price : -1,
        robot: rebelProductPriceResponse ? rebelProductPriceResponse.price : -1,
        endEffector: grabberProductPriceResponse ? grabberProductPriceResponse.price : -1
      },
      translationPrices: translationPricesResponse,
      robots: robots ?? {},
      usecaseCategories,
      initialCategoryUsecases: usecases ?? [],
      testimonials
    }
  };
};

export default IndexPage;
