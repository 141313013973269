/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { PageSection } from '../page-section/PageSection';
import { useTranslation } from 'next-i18next';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import pathMap from '~/shared/pathMap';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCountryCode } from '~/lib/hooks/useCountryCode';

export const Testimonial = ({ testimonial }: { testimonial: any }) => {
  const { t } = useTranslation();
  const router = useRouter();

  // pretty sure this can be improved, might check if the linked testimonial solution slug is the same as the current one
  // this approach works sufficiently for now /shrug
  const isDetailPage = router.asPath.startsWith('/solutions/');

  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    const className =
      'bg-white rounded-lg shadow-lg border flex flex-col md:gap-12 md:flex-row mt-5 md:mt-0 w-full';

    if (isDetailPage || !testimonial.fields.solution) {
      return <div className={className}>{children}</div>;
    } else {
      return (
        <Link className={className} href={pathMap.solution.as([testimonial.fields.solution.fields.slug])}>
          {children}
        </Link>
      );
    }
  };

  return (
    <Wrapper>
      <img
        className="flex-none w-[128px] self-center mt-4 md:self-start md:mt-8 md:ml-8"
        src={testimonial.fields.logo.fields.file.url}
        alt={testimonial.fields.logo.fields.title || testimonial.fields.name}
      />
      <div className="flex flex-col mx-4 md:mx-0 mb-8 mt-4 md:mt-8 md:pr-5">
        <div className="text-xs text-gray-800">{testimonial.fields.name}</div>
        <div className="text-3xl mt-2 text-black font-inter grow md:grow-0">{testimonial.fields.title}</div>
        <div className="mt-3 text-gray-800 font-light font-inter whitespace-pre-wrap grow">
          {testimonial.fields.text}
        </div>
        {testimonial.fields.author && (
          <div className="text-md grayscale/80 relative mt-2">— {testimonial.fields.author}</div>
        )}
        <div className={classNames('mt-8', (!testimonial.fields.solution || isDetailPage) && 'hidden')} />
      </div>

      <div
        className={classNames(
          'absolute right-8 bottom-5 self-end text-brand-darkPurple font-inter',
          (!testimonial.fields.solution || isDetailPage) && 'hidden'
        )}
      >
        {t('landing:testimonials.viewSolution')}
      </div>
    </Wrapper>
  );
};

type TestimonailsSectionProps = {
  className?: string;
  testimonials: Array<any>;
};

export const TestimonailsSection = ({ className, testimonials }: TestimonailsSectionProps) => {
  const { t } = useTranslation();
  const countryIsoCode = useCountryCode();

  SwiperCore.use([Autoplay, Pagination, Navigation]);

  return (
    <PageSection
      title={t('landing:testimonials.title')}
      className={className}
      containerClassName="px-5 !md:px-0 w-full min-w-full"
    >
      <Swiper
        spaceBetween={32}
        slidesPerView={'auto'}
        rewind={true}
        pagination={{
          clickable: true,
          bulletClass: twMerge('swiper-pagination-bullet', 'bg-brand-darkPurple w-3 h-3')
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        initialSlide={Math.floor(testimonials.length / 2)} // start in the middle
        centeredSlides
      >
        {testimonials
          .filter((t) => {
            const filterType: 'whitelist' | 'blacklist' | undefined = t.fields.countryFilterType;

            if (!filterType || !countryIsoCode) {
              return true;
            }
            const countries: Array<string> = t.fields.countryFilterList || [];
            const includes = countries.includes(countryIsoCode);
            return filterType === 'whitelist' ? includes : !includes;
          })
          .map((testimonial) => (
            <SwiperSlide
              // className="md:max-w-3xl lg:max-w-4xl bg-white rounded-lg shadow-lg mb-16 flex select-none relative h-auto"
              className="md:max-w-3xl lg:max-w-4xl mb-16 flex h-auto"
              key={testimonial.sys.id}
            >
              <Testimonial testimonial={testimonial} />
            </SwiperSlide>
          ))}
      </Swiper>
    </PageSection>
  );
};
