import Image from 'next/image';
import type { IconProps } from 'react-feather';
import * as FeatherIcons from 'react-feather';
import type { UsecaseCategory } from '~/types';

export type IconPropsWithRequiredSize = IconProps & {
  size: number;
};

export function getFeatherIconByName(name: string): React.FC<IconPropsWithRequiredSize> | null {
  // eslint-disable-next-line import/namespace
  const Icon = FeatherIcons[name as keyof typeof FeatherIcons];

  if (!Icon) {
    return null;
  }

  return (props: IconProps) => <Icon {...props} />;
}

export const getUsecaseCategoryIcon = (
  category: Pick<UsecaseCategory, 'icon'>
): React.FC<IconPropsWithRequiredSize> | null => {
  const icon = category.icon;

  if (!icon) {
    return null;
  }

  const override = icon.svgOverride;

  if (override) {
    return customUsecaseCategoryIcon(category);
  }

  if (icon.name) {
    return getFeatherIconByName(icon.name);
  }

  return null;
};

export const customUsecaseCategoryIcon = (
  category: Pick<UsecaseCategory, 'icon'>
): React.FC<IconPropsWithRequiredSize> | null => {
  const override = category.icon?.svgOverride;

  if (!override || !override.file) {
    return null;
  }

  return (props: IconPropsWithRequiredSize) => (
    <Image
      src={`https:${override.file.url}`}
      alt={override.description}
      width={props.size}
      height={props.size}
      className={props.className}
    />
  );
};
